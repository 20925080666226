














































































import { defineComponent, PropType } from '@vue/composition-api';
import { AlertEntity, AlertEvent } from '../../interfaces';
import { AlertEventType } from '../../constants';
import { ValidationProvider, extend } from 'vee-validate';
import { maxValueValidator, minValueValidator } from '@/app/validators';

extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);

export default defineComponent({
    name: 'FailedExecutions',
    components: {
        ValidationProvider,
    },
    props: {
        alertEvent: {
            type: Object as PropType<AlertEvent>,
        },
        alertEntities: {
            type: Array as PropType<AlertEntity[]>,
            default: () => [],
        },
    },
    setup(props) {
        const analyticsTasks = props.alertEntities.filter((e) => e.type === 'task');

        return {
            AlertEventType,
            analyticsTasks,
        };
    },
});
