
































































































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import {
    Alert,
    AlertEntity,
    AlertEvent,
    CompletenessCondition,
    ImprovementCondition,
    StatusConditions,
    TimelinessCondition,
} from '../interfaces';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { AlertEventType, AlertSourceType } from '../constants';
import { FailedExecutions, QualityMetrics } from './event-type-configurations';
import { useAlerts } from '../composable';
import { QualityMetrics as QualityMetricsConstants } from '../constants';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import * as R from 'ramda';

export default defineComponent({
    name: 'AlertEvents',
    props: {
        alert: {
            type: Object as PropType<Alert>,
        },
        alertEvent: {
            type: Object as PropType<AlertEvent>,
        },
        alertEntities: {
            type: Array as PropType<AlertEntity[]>,
            default: () => [],
        },
        editingEventId: {
            type: Number,
            required: true,
        },
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        ExclamationIcon,
    },
    setup(props, { emit, root }) {
        const { getSourceTypeFromEventType, getQualityMetricFromEventType } = useAlerts(root);

        const nestedEntities = computed(() =>
            props.alertEntities.filter((e) => e.type !== props.alert?.sourceType && e.type !== 'task'),
        );

        const alertEventTypes = computed(() => {
            const options = {
                [AlertSourceType.DataCheckin]: [
                    {
                        title: 'Data Check-in Pipeline has Failed',
                        value: AlertEventType.DataCheckinFailed,
                    },
                ],
                [AlertSourceType.Analytics]: [
                    { title: 'Analytics Pipeline has Failed', value: AlertEventType.AnalyticsFailed },
                    { title: 'Analytics Pipeline has Skipped', value: AlertEventType.AnalyticsSkipped },
                    { title: 'Analytics Pipeline has Failed on Task', value: AlertEventType.AnalyticsTaskFailed },
                ],
                [AlertSourceType.Dataset]: [
                    { title: 'Dataset Timeliness remains Stale', value: AlertEventType.DatasetTimeliness },
                    { title: 'Dataset Completeness is below a limit', value: AlertEventType.DatasetCompleteness },
                    {
                        title: 'Dataset Quality Improvements exceeds a limit',
                        value: AlertEventType.DatasetQualityImprovements,
                    },
                ],
                [AlertSourceType.Result]: [
                    {
                        title: 'Result Timeliness remains Stale',
                        value: AlertEventType.ResultTimeliness,
                    },
                    { title: 'Result Completeness is below a limit', value: AlertEventType.ResultCompleteness },
                ],
            };

            if (nestedEntities.value.length) {
                options[AlertSourceType.DataCheckin] = options[AlertSourceType.DataCheckin].concat([
                    {
                        title: 'Output Dataset Timeliness remains Stale',
                        value: AlertEventType.DatasetTimeliness,
                    },
                    {
                        title: 'Output Dataset Completeness is below a limit',
                        value: AlertEventType.DatasetCompleteness,
                    },
                    {
                        title: 'Output Dataset Quality Improvements exceeds a limit',
                        value: AlertEventType.DatasetQualityImprovements,
                    },
                ]);

                options[AlertSourceType.Analytics] = options[AlertSourceType.Analytics].concat([
                    {
                        title: 'Output Result Timeliness remains Stale',
                        value: AlertEventType.ResultTimeliness,
                    },
                    {
                        title: 'Output Result Completeness is below a limit',
                        value: AlertEventType.ResultCompleteness,
                    },
                ]);

                options[AlertSourceType.Dataset] = options[AlertSourceType.Dataset].concat([
                    { title: 'Parent Data Check-in Pipeline has Failed', value: AlertEventType.DataCheckinFailed },
                ]);

                options[AlertSourceType.Result] = options[AlertSourceType.Result].concat([
                    { title: 'Parent Analytics Pipeline has Failed', value: AlertEventType.AnalyticsFailed },
                    { title: 'Parent Analytics Pipeline has Skipped', value: AlertEventType.AnalyticsSkipped },
                    {
                        title: 'Parent Analytics Pipeline has Failed on Task',
                        value: AlertEventType.AnalyticsTaskFailed,
                    },
                ]);
            }
            return options;
        });

        const isStatusEvent = computed(() =>
            [
                AlertEventType.DataCheckinFailed,
                AlertEventType.AnalyticsFailed,
                AlertEventType.AnalyticsSkipped,
                AlertEventType.AnalyticsTaskFailed,
            ].includes(props.alertEvent?.type as AlertEventType),
        );

        const isQualityEvent = computed(() =>
            [
                AlertEventType.DatasetTimeliness,
                AlertEventType.DatasetCompleteness,
                AlertEventType.DatasetQualityImprovements,
                AlertEventType.ResultTimeliness,
                AlertEventType.ResultCompleteness,
            ].includes(props.alertEvent?.type as AlertEventType),
        );

        const alertEventComponent = computed(() => {
            if (isStatusEvent.value) return FailedExecutions;
            if (isQualityEvent.value) return QualityMetrics;
            return null;
        });

        const requiresNestedEntity = computed(
            () =>
                props.alert?.sourceType &&
                ((isStatusEvent.value &&
                    [AlertSourceType.Dataset, AlertSourceType.Result].includes(props.alert.sourceType)) ||
                    (isQualityEvent.value &&
                        [AlertSourceType.DataCheckin, AlertSourceType.Analytics].includes(props.alert.sourceType))),
        );

        const existingAlertEventId = computed(() => {
            const fields = ['conditions', 'entityId', 'entityType', 'sourceType', 'type', 'criticality'];
            const alertEvents: any = props.alert?.events.map((event) => R.pick(fields, event));
            alertEvents.forEach((event: any) => {
                event.entityId = String(event.entityId);
            });
            const currentEvent: any = R.pick(fields, props.alertEvent);
            currentEvent.entityId = String(currentEvent.entityId);
            return alertEvents.findIndex((event: any) => JSON.stringify(event) === JSON.stringify(currentEvent));
        });

        const hasChanges = computed(() => existingAlertEventId.value === -1);

        const eventAlreadyExists = computed(
            () => !hasChanges.value && existingAlertEventId.value !== props.editingEventId,
        );

        const handleEventTypeChange = () => {
            if (isStatusEvent.value) {
                const conditions: StatusConditions = {
                    status: {
                        type: 'failed',
                        count: null,
                        interval: null,
                        intervalUnit: 'h',
                        taskId: '',
                    },
                };
                if (props.alertEvent?.type === AlertEventType.AnalyticsSkipped) conditions.status.type = 'skipped';
                emit('change-conditions', conditions);
            } else if (isQualityEvent.value) {
                const qualityMetric = getQualityMetricFromEventType(props.alertEvent?.type as AlertEventType);
                switch (qualityMetric) {
                    case QualityMetricsConstants.Timeliness: {
                        const conditions: TimelinessCondition = {
                            timeliness: 'stale',
                            interval: null,
                            intervalUnit: 'h',
                        };
                        emit('change-conditions', { metrics: conditions });
                        break;
                    }
                    case QualityMetricsConstants.Completeness: {
                        const conditions: CompletenessCondition = {
                            completeness: null,
                        };
                        emit('change-conditions', { metrics: conditions });
                        break;
                    }
                    case QualityMetricsConstants.QualityImprovenents: {
                        const conditions: ImprovementCondition = {
                            improvementPercentage: null,
                        };
                        emit('change-conditions', { metrics: conditions });
                        break;
                    }
                    default:
                    //do nothing
                }
            }
            emit('has-nested-entity', requiresNestedEntity.value);
        };

        return {
            alertEventTypes,
            alertEventComponent,
            requiresNestedEntity,
            nestedEntities,
            existingAlertEventId,
            hasChanges,
            eventAlreadyExists,
            handleEventTypeChange,
            getSourceTypeFromEventType,
        };
    },
});
