


























































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { AlertEvent } from '../../interfaces';
import { AlertEventType, QualityMetrics } from '../../constants';
import { extend, ValidationProvider } from 'vee-validate';
import { useAlerts } from '../../composable';
import { maxValueValidator, minValueValidator } from '@/app/validators';

extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);

export default defineComponent({
    name: 'QualityMetrics',
    components: {
        ValidationProvider,
    },
    props: {
        alertEvent: {
            type: Object as PropType<AlertEvent>,
        },
    },
    setup(props, { root }) {
        const { getQualityMetricFromEventType } = useAlerts(root);

        const qualityMetric = computed(() =>
            props.alertEvent?.type ? getQualityMetricFromEventType(props.alertEvent.type) : '',
        );

        return {
            AlertEventType,
            QualityMetrics,
            qualityMetric,
        };
    },
});
