



























import { defineComponent, PropType, ref } from '@vue/composition-api';
import { useAlerts } from '../composable';
import { Alert, AlertEntity, AlertEvent, EventConditions } from '../interfaces';
import AlertEventConfiguration from './AlertEventConfiguration.vue';
import AlertSummary from './AlertSummary.vue';
import * as R from 'ramda';

export default defineComponent({
    name: 'AlertEvents',
    props: {
        alert: {
            type: Object as PropType<Alert>,
            requred: true,
        },
        alertEntities: {
            type: Array as PropType<AlertEntity[]>,
            default: () => [],
        },
        editingMode: {
            type: Boolean,
            default: false,
        },
        loadingAlert: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AlertEventConfiguration,
        AlertSummary,
    },
    setup(props, { emit, root }) {
        const { getSourceTypeFromEventType, getEntityTypeFromSourceType } = useAlerts(root);
        const editingEventId = ref<number>(-1);
        const editingEvent = ref<AlertEvent | null>(null);
        const newEvent: AlertEvent = {
            entityId: null,
            entityType: null,
            sourceType: null,
            type: null,
            conditions: null,
            criticality: 'yellow',
        };

        const cancelAlertEvent = () => {
            editingEventId.value = -1;
            emit('editing-mode', false);
            editingEvent.value = null;
        };

        const newAlertEvent = () => {
            emit('editing-mode', true);
            editingEvent.value = R.clone(newEvent);
        };

        const saveAlertEvent = () => {
            if (editingEvent.value) {
                if (editingEventId.value === -1) {
                    props.alert?.events.push(editingEvent.value);
                } else {
                    emit('change-alert-event', editingEvent.value, editingEventId.value);
                }
            }
            cancelAlertEvent();
        };

        const removeAlertEvent = (index: number) => {
            cancelAlertEvent();
            emit('remove-alert-event', index);
        };

        const editAlertEvent = (index: number) => {
            if (editingEventId.value === index) {
                cancelAlertEvent();
            } else {
                editingEventId.value = index;
                emit('editing-mode', true);
                if (props.alert) editingEvent.value = R.clone(props.alert.events[index]);
            }
        };

        const changeEventConditions = (payload: EventConditions | null) => {
            if (editingEvent.value) editingEvent.value.conditions = payload;
        };

        const changeNestedEntity = (requiresNestedEntity: boolean) => {
            if (editingEvent.value && props.alert)
                if (requiresNestedEntity) {
                    editingEvent.value.entityId = null;
                    editingEvent.value.sourceType = getSourceTypeFromEventType(editingEvent.value.type);
                    editingEvent.value.entityType = getEntityTypeFromSourceType(editingEvent.value.sourceType);
                } else {
                    editingEvent.value.entityId = props.alert.entityId;
                    editingEvent.value.sourceType = props.alert.sourceType;
                    editingEvent.value.entityType = props.alert.entityType;
                }
        };

        return {
            newAlertEvent,
            editingEventId,
            editingEvent,
            changeEventConditions,
            changeNestedEntity,
            saveAlertEvent,
            editAlertEvent,
            cancelAlertEvent,
            removeAlertEvent,
        };
    },
});
