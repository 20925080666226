


























































import { Scrollbar, Tabs } from '@/app/components';
import { useAxios, useFeatureFlags, useQueryParams } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import store from '@/app/store';
import { AuthAPI } from '@/modules/user/api';
import UserPreferences from '@/modules/user/components/UserPreferences.vue';
import { CheckIcon, ChevronLeftIcon } from '@vue-hero-icons/outline';
import { Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import CustomAlerts from './CustomAlerts.vue';

export default defineComponent({
    name: 'Alerts',
    metaInfo: {
        title: 'Alerts',
    },
    components: {
        OrbitSpinner,
        Tabs,
        Scrollbar,
        UserPreferences,
        CustomAlerts,
        ChevronLeftIcon,
        CheckIcon,
    },
    setup(props, { root }) {
        const { exec, loading, error } = useAxios(true);
        const { flag } = useFeatureFlags();
        const isAnalyticsEnabled: Ref<boolean> = flag('analytics');
        const isModelManagerEnabled: Ref<boolean> = flag('model-manager');
        const isCustomAlertsEnabled: Ref<boolean> = flag('alerts');
        const user = ref(store.state.auth.user);
        const router = useRouter();
        const { get, set } = useQueryParams(root, router, 'alerts');

        const tabs = computed(() => {
            const availableTabs = [
                {
                    id: 0,
                    title: 'Pre-Configured Notifications',
                    description: 'Define your preferences for the platform notifications',
                },
            ];
            if (isCustomAlertsEnabled.value && !!user.value?.organisationId)
                availableTabs.push({
                    id: 1,
                    title: 'Custom Monitoring Alerts',
                    description: 'Configure your own monitoring alerts',
                });
            return availableTabs;
        });
        const activeTab = ref(parseInt(get('tab', false, 0), 10));

        const defaultPreferences: any = ref({});
        let cloneDefaultPreferences: any = ref({});

        //Get user preferences
        exec(AuthAPI.getUserPreferences())
            .then((res: any) => {
                defaultPreferences.value = res.data;

                const notificationReferenceType = {};

                if (user.value?.organisationId) {
                    notificationReferenceType['dcj'] = {
                        label: 'Data Check-in Pipelines',
                        value: {},
                    };

                    if (isAnalyticsEnabled.value)
                        notificationReferenceType['workflow'] = {
                            label: 'Analytics Pipeline Executions',
                            value: {},
                        };
                }

                if (isModelManagerEnabled.value)
                    notificationReferenceType['concept'] = {
                        label: 'Data Model Management',
                        value: {},
                    };

                if (
                    R.hasPath(['notifications', 'admin.user.deleted'], defaultPreferences.value) ||
                    R.hasPath(['notifications', 'admin.organisation.deleted'], defaultPreferences.value)
                ) {
                    notificationReferenceType['admin'] = {
                        label: 'Administration',
                        value: {},
                    };
                }

                //group events by reference type. Model Suggestions, Data Check-in Pipelines, Workflows
                if (defaultPreferences.value?.notifications) {
                    Object.keys(defaultPreferences.value.notifications).forEach((eventKey) => {
                        const referenceType = eventKey.split('.')[0];

                        if (notificationReferenceType[referenceType]) {
                            notificationReferenceType[referenceType].value[eventKey] =
                                defaultPreferences.value.notifications[eventKey];
                        }
                    });
                }

                defaultPreferences.value = notificationReferenceType;
                // clone variable needed to track changes on the preferences and make the save button available
                cloneDefaultPreferences.value = R.clone(defaultPreferences.value);
            })
            .catch(() => {
                (root as any).$toastr.e('An error occurred.', 'Error');
            });

        const saveChanges = async () => {
            try {
                // // ungroup events. Now the object is divided by reference type but we need preferences individually
                const updatedUserPreferences = Object.keys(defaultPreferences.value).reduce((p: any, c: any) => {
                    return { ...p, ...defaultPreferences.value[c].value };
                }, {});

                // update preferences to db
                await exec(
                    AuthAPI.updateUserPreferences({
                        notifications: R.mergeAll(updatedUserPreferences),
                    }),
                );
                cloneDefaultPreferences.value = R.clone(defaultPreferences.value);

                (root as any).$toastr.s('User data have been changed successfuly', 'Success');
            } catch (e) {
                (root as any).$toastr.e('Changing user data failed', 'Error');
            }
        };

        const checkDifference = computed(() => {
            return JSON.stringify(cloneDefaultPreferences.value) === JSON.stringify(defaultPreferences.value);
        });

        const cancel = async () => {
            if (window['navigation'].canGoBack) root.$router.go(-1);
            else root.$router.push({ name: 'dashboard' });
        };

        const tabClicked = (idx: number) => {
            activeTab.value = idx;
            set('tab', idx, 0);
        };

        watch(
            () => root.$route.query,
            () => {
                const urlTab = parseInt(get('tab', false, 0), 10);
                if (!isCustomAlertsEnabled.value && urlTab === 1) tabClicked(0);
                else if (urlTab !== activeTab.value) tabClicked(urlTab);
            },
            { immediate: true },
        );

        return {
            user,
            loading,
            error,
            saveChanges,
            cancel,
            tabs,
            activeTab,
            checkDifference,
            tabClicked,
            defaultPreferences,
            isCustomAlertsEnabled,
        };
    },
});
