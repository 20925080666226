var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex text-sm"},[_c('div',{staticClass:"w-5/12 mt-2.5 -mr-1 font-semibold tracking-wide capitalize text-neutral-700"},[_vm._v(" "+_vm._s(_vm.alertEvent.conditions.status.type)+" Executions ")]),_c('div',{staticClass:"w-full font-medium text-neutral-700",class:{ 'flex items-center space-x-4 ': _vm.alertEvent.type !== _vm.AlertEventType.AnalyticsTaskFailed }},[_c('div',{staticClass:"flex items-center space-x-4"},[_c('ValidationProvider',{staticClass:"block",attrs:{"rules":{ required: true, min_value: 1, max_value: 1000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.alertEvent.conditions.status.count),expression:"alertEvent.conditions.status.count",modifiers:{"number":true}},{name:"tooltip",rawName:"v-tooltip",value:(errors.length ? errors[0] : ''),expression:"errors.length ? errors[0] : ''"}],staticClass:"block w-16 text-sm form-input",class:{ 'border-red-600': errors.length > 0 },attrs:{"name":"Number of executions","type":"number"},domProps:{"value":(_vm.alertEvent.conditions.status.count)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.alertEvent.conditions.status, "count", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]}}])}),(_vm.alertEvent.type === _vm.AlertEventType.AnalyticsTaskFailed)?[_c('div',{staticClass:"whitespace-nowrap"},[_vm._v("on task")]),_c('ValidationProvider',{staticClass:"block w-full",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.alertEvent.conditions.status.taskId),expression:"alertEvent.conditions.status.taskId"}],staticClass:"w-full py-2 pl-3 pr-8 text-sm form-input",class:{
                            'border-red-700': errors.length,
                            'text-neutral-500': _vm.alertEvent.conditions.status.taskId === '',
                            'text-black': _vm.alertEvent.conditions.status.taskId !== '',
                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.alertEvent.conditions.status, "taskId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":''}},[_vm._v("Select task...")]),_vm._l((_vm.analyticsTasks),function(task){return _c('option',{key:task.id,domProps:{"value":task.id}},[_vm._v(" "+_vm._s(task.name)+" ")])})],2)]}}],null,false,96764636)})]:_vm._e()],2),_c('div',{staticClass:"flex items-center w-full space-x-4",class:{ 'mt-4': _vm.alertEvent.type === _vm.AlertEventType.AnalyticsTaskFailed }},[_c('div',{staticClass:"whitespace-nowrap"},[_vm._v("within the last")]),_c('ValidationProvider',{staticClass:"block",attrs:{"rules":{ required: true, min_value: 1, max_value: 1000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.alertEvent.conditions.status.interval),expression:"alertEvent.conditions.status.interval",modifiers:{"number":true}},{name:"tooltip",rawName:"v-tooltip",value:(errors.length ? errors[0] : ''),expression:"errors.length ? errors[0] : ''"}],staticClass:"block w-16 text-sm form-input",class:{ 'border-red-600': errors.length > 0 },attrs:{"name":"Interval value","type":"number"},domProps:{"value":(_vm.alertEvent.conditions.status.interval)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.alertEvent.conditions.status, "interval", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]}}])}),_c('ValidationProvider',{staticClass:"block w-full",attrs:{"rules":"required"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.alertEvent.conditions.status.intervalUnit),expression:"alertEvent.conditions.status.intervalUnit"}],staticClass:"w-full py-2 pl-3 pr-8 text-sm form-input",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.alertEvent.conditions.status, "intervalUnit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"m"}},[_vm._v("minutes")]),_c('option',{attrs:{"value":"h"}},[_vm._v("hours")]),_c('option',{attrs:{"value":"d"}},[_vm._v("days")])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }