











































































import { defineComponent } from '@vue/composition-api';
import { PlusIcon } from '@vue-hero-icons/outline';
import { AlertSortingOption } from '../constants/alert-sorting-options.constants';
import { SearchBox, TwSelect, Scrollbar, SvgImage } from '@/app/components';
import { AlertCard, FakeAlertCard } from '../components';
import { useAlerts } from '../composable';
import { useRouter } from '@/app/composable/router';

export default defineComponent({
    name: 'CustomAlerts',
    components: {
        PlusIcon,
        SearchBox,
        TwSelect,
        Scrollbar,
        AlertCard,
        SvgImage,
        FakeAlertCard,
    },

    setup(props, { root }) {
        const router = useRouter();

        const {
            alerts,
            ALERTS_MAX_NUM,
            deleteAlert,
            toggleAlert,
            total,
            searchedAlerts,
            query,
            sortBy,
            sortByChanged,
            fetchAlerts,
            loading,
            alertsLoading,
        } = useAlerts(root);

        const sortingFields = AlertSortingOption.all();

        const editAlert = (id: string) => {
            router.push({ name: 'alerts:edit', params: { id } });
        };

        fetchAlerts();

        return {
            alerts,
            query,
            sortingFields,
            sortBy,
            ALERTS_MAX_NUM,
            editAlert,
            deleteAlert,
            toggleAlert,
            total,
            searchedAlerts,
            sortByChanged,
            loading,
            alertsLoading,
        };
    },
});
