


































































































import { defineComponent, PropType } from '@vue/composition-api';
import { Scrollbar, ConfirmButton } from '@/app/components';
import {
    Alert,
    AlertEntity,
    AlertEvent,
    CompletenessCondition,
    ImprovementCondition,
    MetricsConditions,
    StatusConditions,
    TimelinessCondition,
} from '../interfaces';
import { PlusIcon } from '@vue-hero-icons/outline';
import { XCircleIcon, ExclamationCircleIcon } from '@vue-hero-icons/solid';
import { AlertEventType } from '../constants';
import { useFilters } from '@/app/composable';

export default defineComponent({
    name: 'AlertSummary',
    props: {
        alert: {
            type: Object as PropType<Alert>,
        },
        editingEventId: {
            type: Number,
            required: true,
        },
        editingMode: {
            type: Boolean,
            default: false,
        },
        alertEntities: {
            type: Array as PropType<AlertEntity[]>,
            default: () => [],
        },
        loadingAlert: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        PlusIcon,
        Scrollbar,
        ConfirmButton,
        XCircleIcon,
        ExclamationCircleIcon,
    },
    setup(props, { emit }) {
        const { capitalise } = useFilters();

        const addAlertEvent = () => {
            emit('new-alert-event');
        };

        const getIntervalText = (interval: number | null, intervalUnit: string) => {
            if (!interval) return;
            switch (intervalUnit) {
                case 'm':
                    return `minute${interval > 1 ? 's' : ''}`;
                case 'h':
                    return `hour${interval > 1 ? 's' : ''}`;
                case 'd':
                    return `day${interval > 1 ? 's' : ''}`;
                default:
                    return '';
            }
        };

        const getEntityName = (id: string | null) => {
            const entity = props.alertEntities.find((e) => String(e.id) === String(id));
            return entity?.name ?? id;
        };

        const eventSummary = (event: AlertEvent) => {
            switch (event.type) {
                case AlertEventType.DataCheckinFailed:
                case AlertEventType.AnalyticsFailed:
                case AlertEventType.AnalyticsSkipped:
                case AlertEventType.AnalyticsTaskFailed: {
                    const conditions = event.conditions as StatusConditions;
                    const status = conditions.status;
                    return [
                        `When the ${event.sourceType} pipeline`,
                        getEntityName(event.entityId),
                        `${status.type === 'failed' ? 'fails' : 'is skipped'}`,
                        `${status.count} time${status.count && status.count > 1 ? 's' : ''}`,
                        `${status.taskId.length ? 'on task' : ''}`,
                        `${status.taskId.length ? getEntityName(status.taskId) : ''}`,
                        'within',
                        `${status.interval} ${getIntervalText(status.interval, status.intervalUnit)},`,
                    ];
                }
                case AlertEventType.DatasetTimeliness:
                case AlertEventType.ResultTimeliness: {
                    const conditions = event.conditions as MetricsConditions;
                    const metrics = conditions.metrics as TimelinessCondition;
                    return [
                        `When the ${event.sourceType}`,
                        getEntityName(event.entityId),
                        'remains',
                        `${capitalise(metrics.timeliness)}`,
                        'for',
                        `${metrics.interval} ${getIntervalText(metrics.interval, metrics.intervalUnit)},`,
                    ];
                }
                case AlertEventType.DatasetCompleteness:
                case AlertEventType.ResultCompleteness: {
                    const conditions = event.conditions as MetricsConditions;
                    const metrics = conditions.metrics as CompletenessCondition;
                    return [
                        'When the',
                        'Completeness',
                        `of ${event.sourceType}`,
                        getEntityName(event.entityId),
                        'is below',
                        `${metrics.completeness}%,`,
                    ];
                }
                case AlertEventType.DatasetQualityImprovements: {
                    const conditions = event.conditions as MetricsConditions;
                    const metrics = conditions.metrics as ImprovementCondition;
                    return [
                        'When the',
                        'Quality Improvements',
                        `of ${event.sourceType}`,
                        getEntityName(event.entityId),
                        'exceed',
                        `${metrics.improvementPercentage}%,`,
                    ];
                }

                default:
                    return '';
            }
        };

        return {
            addAlertEvent,
            eventSummary,
        };
    },
});
